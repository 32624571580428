<template>
  <div class="home">
    <div class="container">
      <h1>基于GAP的茶叶生产过程和质量安全智能控制服务中心</h1>
      <div class="main">
        <div class="one" onclick="window.open('http://cypd.znglzx.com/#/')">
          <img src="../assets/icon/云平台.png" />
          <div class="systitle">
            <a href="http://cypd.znglzx.com/#/"
              >基于GAP的茶叶生产过程和质量安全智能控制云平台</a
            >
          </div>
        </div>
        <div class="two" onclick="window.open('http://cypdsjzx.znglzx.com/')">
          <img src="../assets/icon/数据中心.png" />
          <div class="systitle">
            <a href="http://cypdsjzx.znglzx.com/"
              >基于GAP的茶叶生产过程控制点基础数据中心</a
            >
          </div>
        </div>
        <div
          class="three"
          onclick="window.open('https://csff.znglzx.com/#/topology')"
        >
          <img src="../assets/icon/融合.png" />
          <div class="systitle">
            <router-link :to="{ path: '/topology' }">超融合展示</router-link>
          </div>
        </div>
        <div
          class="four"
          onclick="window.open('https://csff.znglzx.com/#/knowBase')"
        >
          <img src="../assets/icon/知识库.png" />
          <div class="systitle">
            <router-link :to="{ path: '/knowBase' }"
              >茶叶质量评定知识库</router-link
            >
          </div>
        </div>
        <div class="five" onclick="window.open('http://teade.znglzx.com/#/')">
          <img src="../assets/icon/茶叶.png" />
          <div class="systitle">
            <a href="http://teade.znglzx.com/#/"
              >茶叶质量物联检测在线服务系统</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  methods: {
    open() {
      this.$router.push({ path: '/knowBase' })
    },
  },
}
</script>
<style scoped>
.home {
  width: 100%;
  height: 100%;
  /* text-align: center; */
  background: url('../assets/bgc.jpg');
  background-size: 100% 100%;
  position: fixed;
}
.container {
  width: 100%;
  height: 100%;
}
.main {
  display: flex;
  flex-direction: row;
  width: 1400px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 200px;
}
.one,
.two,
.three,
.four,
.five {
  width: 200px;
  height: 250px;
  background-color: rgba(64, 151, 42, 0.9);
  margin: 30px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}
a {
  text-decoration: none;
  color: white;
  font-size: 18px;
  /* margin: 100px 20px 0 20px; */
  display: block;
  width: 100%;
  height: auto;
}
.main img {
  width: 80px;
  height: 80px;
  padding-top: 30px;
  padding-left: 10px;
}
.systitle {
  margin: 5px;
}
</style>
