import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path:'/knowBase',
    name: 'knowBase',
    component: () => import('../views/knowBase.vue'),
    redirect:'/knowBase/knowQA',
    children: [
      {
        path: 'knowQA',
        name: 'knowQA',
        component: () => import('../views/knowQA.vue')
      },
      {
        path: 'tea',
        name: 'tea',
        component: () => import('../views/tea.vue')
      }
    ]
  },
  {
    path: '/topology',
    name: 'topology',
    component: () => import('../views/topology.vue'),
  },
  {
    path: '/publicloud',
    name: 'publicloud',
    component: () => import('../views/publicloud.vue'),
  },
  {
    path: '/buildingFour',
    name: 'buildingFour',
    component: () => import('../views/buildingFour.vue'),
  },
  {
    path: '/buildingNine',
    name: 'buildingNine',
    component: () => import('../views/buildingNine.vue'),
  },
  {
    path: '/standardDesign',
    name: 'standardDesign',
    component: () => import('../views/standardDesign.vue'),
  },
  {
    path: '/referenceExample',
    name: 'referenceExample',
    component: () => import('../views/referenceExample.vue'),
  },
  
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
